@use 'sass:list';

@mixin transition($properties: all, $duration: 0.2s, $easing-function: cubic-bezier(0.4, 0, 0.2, 1), $important: false) {
  $transition: ();
  @if (type-of($properties) ==  list) {
    $transition: ();
    @each $property in $properties {
      $transition: $transition, $property $duration $easing-function;
    }
  } @else {
    $transition: ($properties $duration $easing-function);
  }
  @if ($important) {
    -webkit-transition: $transition !important;
    -moz-transition: $transition !important;
    -o-transition: $transition !important;
    transition: $transition !important;
  } @else {
    -webkit-transition: $transition;
    -moz-transition: $transition;
    -o-transition: $transition;
    transition: $transition;
  }
}
