@use "sass:math";

// NOTE: it seems that :vertical, and :horizontal sudo-classes do not work, so we are stuck with applying it to both or none.
@mixin custom-scrollbar(
  $force-webkit: false,
  $track-color: transparent,
  $thumb-color: rgba(131, 137, 146),
) {

  @if ($force-webkit) {
  } @else {
    scrollbar-color: $thumb-color $track-color;
    scrollbar-width: thin;
  }

  &::-webkit-scrollbar {
    -webkit-appearance: none !important;
    transition: none !important;
    width: 7px !important;
    height: 7px !important;
  }

  &::-webkit-scrollbar-track {
    background: $track-color !important;
    border-radius: 4px !important;
  }

  &::-webkit-scrollbar-thumb {
    background: $thumb-color !important;
    border: 1px solid $thumb-color !important;
    border-radius: 4px !important;
    cursor: pointer !important;
  }

  &::-webkit-scrollbar-button {
    display: none;
    background: transparent;
  }
  &::-webkit-scrollbar-corner {
    display: none;
    background: transparent;
  }
}
