@import "../mixins/transition";

@mixin svg-color($color: currentColor, $opacity: 1, $width: 1px) {
  .fill {
    @include transition();
    fill: $color;
    fill-opacity: $opacity;
  }
  .stroke {
    @include transition();
    stroke: $color;
    stroke-opacity: $opacity;
    stroke-width: $width;
  }
}
