@use "sass:math";
@use "sass:list";
@import "mixins";
@import "functions";

// Toastify css var injection.
:root {
  --toastify-toast-min-height: #{theme-var(appSpecific-toast-min-height)} !important;
  --toastify-toast-width: #{theme-var(appSpecific-toast-width)} !important;
  --toastify-font-family: #{theme-var(appSpecific-toast-font-family)} !important;
  --toastify-text-color-dark: #{theme-var(palette-appSpecific-toast-text-color)} !important;
  --toastify-text-color-light: #{theme-var(palette-appSpecific-toast-text-color)} !important;
  --toastify-color-dark: #{theme-var(palette-appSpecific-toast-color)} !important;
  --toastify-color-light: #{theme-var(palette-appSpecific-toast-color)} !important;
}

// General
html {

  * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    scroll-behavior: smooth;

    &:focus, &:active, &:focus-visible {
      outline: none !important;
    }
  }

  a {
    text-decoration: none;

    &:hover, &:focus {
      text-decoration: none;
    }
  }

  code {
    @include font-family(theme-var(fontFamily-code));
  }

  p {
    margin: 0;
  }

  img {
    &.sharp {
      image-rendering: -moz-crisp-edges;
      image-rendering: -o-crisp-edges;
      //noinspection CssInvalidPropertyValue
      image-rendering: -webkit-optimize-contrast;
      -ms-interpolation-mode: nearest-neighbor;
    }
  }

  kbd {
    font-family: inherit;
    padding: 0.2rem 0.4rem;
    font-size: .875em;
    color: theme-var(palette-appSpecific-kbd-color);
    background-color: theme-var(palette-appSpecific-kbd-background);
    border-radius: 0.2rem;
  }

  body {
    @include font-family(theme-var(fontFamily-body));
    margin: 0;
    background-color: theme-var(palette-background-body);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &.force-webkit-scrollbar {
      * {
        @include custom-scrollbar(true);
      }
    }

    &:not(.force-webkit-scrollbar) {
      @include custom-scrollbar(false);
    }
  }

  textarea {
    resize: none;
  }

  // remove Grammarly extension from all the editbale contens of the application
  [contenteditable] ~ grammarly-extension,
  input ~ grammarly-extension,
  textarea ~ grammarly-extension {
    display: none;
  }

}

// Containers
html {

  .panel {

    &-toolbar {
      height: theme-var(appSpecific-panel-toolbar-height);
      background: theme-var(palette-appSpecific-panel-toolbar-background);
      box-shadow: 0 1px 0 0 theme-var(palette-divider);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: 8;
    }

    &-left-panel {

      &-container {
        width: theme-var(appSpecific-panel-left-panel-width);
        background-color: theme-var(palette-appSpecific-panel-left-panel-background);
        position: absolute;
        top: calc(#{theme-var(appSpecific-panel-toolbar-height)} + 0px);
        left: 0;
        bottom: 0;
        z-index: 2;
        user-select: none;
        display: flex;
        flex-direction: column;
      }

      &-content {
        flex-grow: 1;
        position: relative;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
      }

      &-resizer {
        $width: 5px;
        @include transition(background-color);
        position: absolute;
        top: calc(#{theme-var(appSpecific-panel-toolbar-height)} + 0px);
        left: calc(theme-var(appSpecific-panel-left-panel-width) - (#{$width} / 2));
        bottom: 0;
        z-index: 4;
        width: $width;

        &:hover {
          cursor: col-resize;
        }

        &.resizing {
          background-color: theme-var(palette-divider);
        }
      }
    }

    &-right-panel {

      &-container {
        width: theme-var(appSpecific-panel-right-panel-width);
        background-color: theme-var(palette-appSpecific-panel-right-panel-background);
        position: absolute;
        top: calc(#{theme-var(appSpecific-panel-toolbar-height)} + 0px);
        right: 0;
        bottom: 0;
        z-index: 1;
        user-select: none;
        display: flex;
        flex-direction: column;
      }

      &-content {
        flex-grow: 1;
        position: relative;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
      }

      &-resizer {
        $width: 5px;
        @include transition(background-color);
        position: absolute;
        top: calc(#{theme-var(appSpecific-panel-toolbar-height)} + 0px);
        right: calc(theme-var(appSpecific-panel-left-panel-width) - (#{$width} / 2));
        bottom: 0;
        z-index: 4;
        width: $width;

        &:hover {
          cursor: col-resize;
        }

        &.resizing {
          background-color: theme-var(palette-divider);
        }
      }
    }

    &-content-preview {
      &-container {
        position: absolute;
        overflow: hidden;
        width: auto;
        height: auto;
        inset: theme-var(appSpecific-panel-toolbar-height) theme-var(appSpecific-panel-right-panel-width) 0 theme-var(appSpecific-panel-left-panel-width);
      }

      &-content {
        background-color: theme-var(palette-appSpecific-panel-content-preview-background);
        inset: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
      }

    }
  }

  .keyboard-shortcuts {
    &-toggle-button {
      position: fixed;
      z-index: 20;
      bottom: 20px;
      right: 20px;

      > button {
        border-radius: 50%;
        background-color: theme-var(palette-appSpecific-keyboard-shortcuts-panel-toggler-background);
      }
    }

    &-panel {
      position: fixed;
      z-index: 20;
      bottom: 0;
      right: 0;
      left: 0;
      max-height: 100vh;
      overflow-x: hidden;
      overflow-y: auto;
      background-color: theme-var(palette-appSpecific-keyboard-shortcuts-panel-background);
      box-shadow: 0 0 0 1px theme-var(palette-divider);
      display: flex;
      flex-direction: column;

      @supports (-webkit-touch-callout: none) {
        max-height: -webkit-fill-available;
      }

      &-header {
        padding: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      &-toggle-button {
        width: fit-content;
      }

      &-content {
        padding: 0.5rem;


        &-entry {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 0.5rem;
          padding-inline: 1rem;

          &-keys {
            display: flex;
            align-items: center;
            justify-content: flex-end;
          }

          &-key {
            width: 30px;
            height: 30px;
            border: 1px solid theme-var(palette-divider);
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;

            + & {
              margin-left: 0.25rem;
            }

            @for $value from 1 to 10 {
              &:nth-of-type(#{$value}n) {
                animation-delay: calc(#{$value} * 50ms);
              }
            }
          }

          &.applying-keyboard-shortcut {

            .shortcut-title {
              @keyframes applied-keyboard-title {
                0% {
                }
                25% {
                  color: transparent;
                }
                50% {
                  font-weight: 500;
                  transform: scale(1.05);
                  color: theme-var(palette-common-white);
                }
                100% {
                  transform: none;
                  color: theme-var(palette-primary-400);
                }
              }

              animation-name: applied-keyboard-title;
              animation-fill-mode: forwards;
              animation-duration: 600ms;
            }

            .keyboard-shortcuts-panel-content-entry-key {
              @keyframes applied-keyboard {
                0% {
                }
                25% {
                  background-color: transparent;
                  color: transparent;
                }
                50% {
                  background-color: theme-var(palette-common-white);
                  transform: scale(0.9);
                  --palette-text-primary: #{theme-var(palette-common-black)};
                  color: theme-var(palette-common-black);
                }
                100% {
                  background-color: theme-var(palette-primary-400);
                  transform: none;
                  --palette-text-primary: #{theme-var(palette-common-white)};
                  color: theme-var(palette-common-white);
                }
              }

              animation-name: applied-keyboard;
              animation-fill-mode: forwards;
              animation-duration: 600ms;
            }
          }

          &.applied-keyboard-shortcut:not(.applying-keyboard-shortcut) {
            .keyboard-shortcuts-panel-content-entry-key {
              background-color: theme-var(palette-primary-400);
              transform: none;
              color: theme-var(palette-common-white);
            }

            .shortcut-title {
              font-weight: 500;
              color: theme-var(palette-primary-400);
              transform: none;
            }
          }
        }
      }
    }
  }

  .template-panel {

    &-toolbar {
      &-action {
        --radius-sm: 0;
        --palette-neutral-plainHoverBg: #{theme-var(palette-common-black)};

        &-icon {
          @include svg-color(theme-var(palette-common-white));
        }

        &-arrow {
          @include transition((margin-top), 0.15s);
          @include svg-color(theme-var(palette-common-white));
          margin-top: 0;
          margin-left: 0.4rem;
        }

        &:hover,
        &:active {
          .template-panel-toolbar-action-arrow {
            margin-top: 6px;
          }
        }

        &.active {
          transition: none;
          background-color: theme-var(palette-primary-400);
        }
      }

      &-title {
        --Input-focusedThickness: 0;
        --Input-focusedHighlight: transparent;
        height: theme-var(appSpecific-template-panel-toolbar-title-height);
        background-color: theme-var(palette-background-level1);

        &-text {
          font-weight: theme-var(fontWeight-lg);
        }

        input {
          text-align: center;
          font-weight: theme-var(fontWeight-lg);
        }
      }

      &-button {
        @include svg-color();
        margin-right: 0.5rem;
      }
    }

    &-pages-panel {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      max-height: 100%;

      &-list {
        --List-decorator-size: 1rem;
        flex-grow: 1;
        margin-inline: -10px;
        padding: 0;

        &.dragging-items {
          .template-panel-pages-panel-list-item {
            --palette-neutral-plainActiveBg: transparent;
          }
        }

        &-item {
          height: theme-var(appSpecific-template-panel-pages-panel-item-height);
          cursor: pointer;
          width: 100%;
          font-weight: theme-var(fontWeight-md);

          &:hover {
            background-color: theme-var(palette-neutral-plainActiveBg);
          }

          svg {
            @include svg-color(theme-var(palette-common-white));
          }

          &-start-decorator {
            display: flex;
            align-items: center;
            justify-content: center;
          }

          &-prefix-decorator {
            --List-decorator-size: 0.5rem;
          }

          &-content-input {
            margin-inline: 0.25rem -0.5rem;
          }

          &-content-text {
            padding-inline: 0.75rem 0;
          }

          &.dragging {
            opacity: 0;
          }

          &.chosen {
            box-shadow: 0 1px 0 1px theme-var(palette-common-white);
          }
        }
      }

      &-header {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 10px;
        box-shadow: 0 1px 0 0 theme-var(palette-divider);
        height: theme-var(appSpecific-template-panel-pages-panel-header-action-height);

        &-action {
          --palette-neutral-plainActiveBg: transparent;
          height: theme-var(appSpecific-template-panel-pages-panel-header-action-height);
          padding-inline: 0;
          max-width: 50%;

          &-arrow {
            @include svg-color(theme-var(palette-common-white));
            @include transition((transform), 0.15s);
            min-width: 8px;
            min-height: 8px;
            margin-left: 0.25rem;
          }

          &.active {
            svg:last-of-type {
              transform: rotate(-180deg);
            }
          }
        }

        &-expanded-section {

          &-content {
            display: flex;
            flex-direction: column;
            padding: 0 10px;

            &-header {
              display: flex;
              align-items: center;
              justify-content: space-between;
              min-height: theme-var(appSpecific-template-panel-pages-panel-header-expanded-section-height);
              margin-top: 0.5rem;

              kbd {
                margin-inline-end: -0.15rem;
              }
            }

            .template-panel-pages-panel-list {
              //@include custom-scrollbar();
              overflow-y: auto;

              &-item {
                &-content-input {
                  margin-inline: 0.25rem -0.5rem;
                }

                &-content-text {
                  padding-inline: 0.75rem 0;
                }
              }
            }
          }

          &-resizer {
            width: 100%;
            position: relative;

            .resizer {
              $height: 8px;
              @include transition(box-shadow);
              position: absolute;
              left: 0;
              right: 0;
              bottom: 0;
              height: $height;
              box-shadow: 0 1px 0 0 theme-var(palette-divider);
              background-color: transparent;
              cursor: row-resize;

              &.resizing {
                box-shadow: 0 3px 0 0 theme-var(palette-divider);
              }
            }
          }
        }

      }

      &-content {
        width: 100%;
        height: 100%;
        max-height: inherit;
        overflow-y: auto;
        overflow-x: hidden;
        touch-action: pan-y;
        padding-top: 0.5rem;
        //@include custom-scrollbar();

        &-container {
          position: relative;
          width: 100%;
          cursor: default;
          flex-grow: 1;
          overflow: hidden;
        }

        .template-panel-pages-panel-list {
          margin-inline: 0;
        }

        &-element {
          border: 1px solid transparent;

          .template-panel-pages-panel-list-item {

            &-prefix-decorator {
              position: relative;
            }

            &-start-decorator {
              --List-decorator-size: 1rem;


              svg {
                width: 14px;
                height: 14px;
              }
            }
          }

          &-toggler {
            position: absolute;
            left: -0.5rem;
            --IconButton-size: 1rem;
            --palette-neutral-plainHoverBg: transparent;
            --palette-neutral-plainActiveBg: transparent;

            svg {
              @include svg-color(theme-var(palette-common-white));
              @include transition(transform);
              transform: rotate(-90deg);
            }

            &.active {
              svg {
                transform: rotate(0deg);
              }
            }
          }

          &-wrapper {
            --palette-neutral-plainActiveBg: transparent;
            padding: 0;

            .template-panel-pages-panel-content-element {
              width: calc(100%);
              padding-inline-start: calc(var(--nested-level) * 1.5rem);
              margin: 0;
            }

            &.dragging-items {
              .template-panel-pages-panel-content-element {
                border-color: transparent;
              }
            }
          }

          &:hover {
            background-color: transparent;
            border-color: theme-var(palette-primary-main);
          }

          &.selected {
            background-color: theme-var(palette-primary-400);
          }

          &.parent-selected {
            background-color: theme-var(palette-primary-500);
          }

        }

      }

      &-property-input {
        #{with-theme-prefix(Input-minHeight)}: 1.5rem;
      }

    }

    &-properties-panel {
      flex-grow: 1;
      display: flex;
      flex-direction: column;

      &-header {
        width: 100%;
        padding: 0 10px;
        box-shadow: 0 1px 0 0 theme-var(palette-divider);
        display: flex;
        align-items: center;
        height: theme-var(appSpecific-template-panel-properties-panel-header-title-height);

        &-title {
          text-align: start;
          font-weight: theme-var(fontWeight-lg);
        }
      }

      &-content {
        width: 100%;
        flex-grow: 1;
        overflow-y: auto;
        overflow-x: hidden;
        touch-action: pan-y;
        display: flex;
        flex-direction: column;
        //@include custom-scrollbar();
      }

      &-property-group {
        box-shadow: 0 1px 0 0 theme-var(palette-divider);
        width: 100%;
        display: flex;
        flex-direction: column;

        &-grid {
          padding: 10px 10px;
        }

        &-inner-grid {
          padding-bottom: 0.3rem;

          &:first-child {
            font-weight: 700;
            padding: 0.5rem 10px 1rem;
          }
        }
      }

      &-property-input {
        --Input-minHeight: #{theme-var(appSpecific-template-panel-properties-panel-property-height)};
      }

      &-property-selection-input {
        --Select-minHeight: #{theme-var(appSpecific-template-panel-properties-panel-property-height)};

        &-option-icon {
          margin-right: 0.6rem;
          margin-left: 0.3rem;

          &.selected {
            @include svg-color(theme-var(palette-common-white));
          }
        }

        + .JoySelect-listbox {
          //@include custom-scrollbar();
          max-height: 300px;
          overflow-y: auto;
        }
      }

      &-property-icons-group {
        @include svg-color(theme-var(palette-common-white));
        width: 100%;

        > * {
          width: calc(100% / var(--count));
          min-height: theme-var(appSpecific-template-panel-properties-panel-property-height);
          padding: 0;

          button {
            width: 100%;
            min-height: theme-var(appSpecific-template-panel-properties-panel-property-height);
            padding: 0;
          }

          svg {
            width: 18px;
            height: 18px;
          }
        }
      }

      &-property-color-input {
        transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        border-radius: 3px;

        .color-text-field {

          &-color-input {
            flex: 7;
            border-right: 1px solid transparent;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;

            &:hover {
              box-shadow: none;
            }
          }

          &-alpha-input {
            flex: 4;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;

            &:hover {
              box-shadow: none;
            }
          }
        }

        &:hover {
          box-shadow: 0 0 0 1px theme-var(palette-divider);

          .color-text-field {
            &-color-input {
              border-right-color: theme-var(palette-divider);
              box-shadow: none;
            }

            &-alpha-input {
              box-shadow: none;
            }
          }
        }

        &.focused {
          box-shadow: 0 0 0 2px theme-var(palette-primary-400);

          .color-text-field {
            &-color-input {
              border-right-color: theme-var(palette-divider);
              box-shadow: none;
            }

            &-alpha-input {
              box-shadow: none;
            }
          }
        }
      }

      &-image-manipulator-button {
        @include svg-color(theme-var(palette-text-tertiary));
      }
    }

    &-content {
      //@include custom-scrollbar();
      inset: 0;
      width: 100%;
      height: 100%;
      overflow: auto;

      &-empty-template {
        max-width: theme-var(appSpecific-template-panel-content-empty-template-width);
        padding: 2rem;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;

        &-entry {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 1rem;
        }
      }

      &-element-manager {
        width: 100%;
        height: 100%;
        background-color: theme-var(palette-common-white);
        position: relative;
        overflow: hidden;
        backface-visibility: hidden;
        -moz-backface-visibility: hidden;
        -webkit-backface-visibility: hidden;

        .template-panel-content-element-manager {
          background-color: transparent;
        }

        &.new-element-selected {
          box-shadow: 0 0 5px 5px transparent;
          @include keyframes(glowing-page) {
            from {
              box-shadow: 0 0 5px 2px theme-var(palette-primary-400);
            }
            to {
              box-shadow: 0 0 30px 5px theme-var(palette-primary-400);
            }
          }
          animation-name: glowing-page;
          animation-fill-mode: backwards;
          animation-direction: alternate;
          animation-iteration-count: 2;
          animation-duration: 500ms;
          animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
          cursor: copy;

          .template-panel-content-element-manager[data-element-manager='true'] {
            animation-name: glowing-page;
            animation-fill-mode: backwards;
            animation-direction: alternate;
            animation-iteration-count: 2;
            animation-duration: 500ms;
            animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
            cursor: copy;
          }
        }
      }

      &-component {

        &-page {
          position: relative;
          width: 100%;
          height: 100%;
        }

        &-element {
          $connector-size: 10px;

          &-scrd {
            @include transition(box-shadow);

            &-handle {
              display: flex;
            }

            &-resizer {
              @include transition(opacity);
              width: $connector-size;
              height: $connector-size;
              background-color: theme-var(palette-common-white);
              border: 1px solid theme-var(palette-primary-400);
              margin: auto;
              opacity: 0;
            }

            &-connector {
              @include transition(opacity);
              width: $connector-size;
              height: $connector-size;
              border-radius: 50%;
              background-color: theme-var(palette-common-white);
              border: 1px solid theme-var(palette-primary-400);
              cursor: pointer;
              opacity: 0;
              margin: auto;
            }

            &.highlighted, &:hover {
              box-shadow: 0 0 0 css-min(list.slash(250px, var(--template-page-scale)), 2.5px) theme-var(palette-primary-400);

              > div:last-of-type {
                > div.template-panel-content-component-element-scrd-handle {
                  .template-panel-content-component-element-scrd-connector {
                    cursor: pointer;
                    opacity: 1;
                  }

                  .template-panel-content-component-element-scrd-resizer {
                    opacity: 1;
                  }
                }
              }
            }

            &.selected {
              box-shadow: 0 0 0 css-min(list.slash(200px, var(--template-page-scale)), 2px) theme-var(palette-primary-400);

              > div:last-of-type {
                > div.template-panel-content-component-element-scrd-handle {
                  .template-panel-content-component-element-scrd-resizer {
                    opacity: 1;
                  }
                }
              }
            }

            &.container:not(.content-focused) {
              cursor: pointer;
            }

            > span {
              width: 100%;
              height: 100%;
              position: absolute;
            }

            &.content-focusable {
              > span {
                pointer-events: none;
              }

              &.content-focused {
                box-shadow: 0 0 0 css-min(list.slash(200px, var(--template-page-scale)), 2px) theme-var(palette-secondary-main);

                &:hover {
                  box-shadow: 0 0 0 css-min(list.slash(200px, var(--template-page-scale)), 2px) theme-var(palette-secondary-main);
                }

                > span {
                  pointer-events: auto;
                }
              }
            }

          }

          &-border {
            position: relative;

            &:before {
              @include transition(opacity);
              position: absolute;
              content: '';
              width: $connector-size;
              height: $connector-size;
              border-radius: 50%;
              background-color: theme-var(palette-common-white);
              border: 1px solid theme-var(palette-primary-400);
              opacity: 0;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
            }

            &.highlighted {
              &:before {
                opacity: 1;
              }
            }
          }

          &-text {
            border: none;
            background-color: transparent;
            padding: 0;
            margin: 0;
            border-image-width: 0;
            cursor: move;
            overflow-y: hidden;

            &.editing {
              cursor: text;
            }
          }

          &-barcode {
            position: relative;
            width: 100%;
            height: 100%;

            canvas {
              width: 100%;
              height: 100%;
              object-fit: contain;
              object-position: center;
            }

            input {
              position: absolute;
              border: none;
              left: 0;
              right: 0;
              text-align: center;

              &:hover {
                border: none;
              }
            }
          }

          &-avery-label {
            font-size: 0;

            &-rect {
              width: 100%;
              height: 100%;
              border: 1px dashed rgb(209, 209, 208);
              position: relative;

              &-wrapper {
                position: relative;
                display: inline-block;
              }
            }
          }

          &-grid {
            font-size: 0;

            &-rect {
              width: 100%;
              height: 100%;
              border: 1px dashed rgb(209, 209, 208);
              position: relative;

              &-wrapper {
                position: relative;
                display: inline-block;
              }
            }
          }

          &-image {
            pointer-events: none;
          }

          &-line {
            &-container {
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }

          &-html {
            overflow-x: hidden;
            overflow-y: hidden;
            font-size: initial;
          }

        }
      }
    }

    &-loading {

      .panel {
        &-toolbar {
          background: none;
          box-shadow: none;
        }

        &-left-panel-container {
          background-color: transparent;
        }

        &-right-panel-container {
          background-color: transparent;
        }

        &-right-panel-content {
          align-items: flex-end;
        }

        &-content-preview {

        }
      }

      &-toolbar {
        $anim-name: unique-id();
        opacity: 0;
        height: 0;
        background-color: transparent;
        box-shadow: 0 1px 0 0 transparent;
        @include keyframes($anim-name) {
          100% {
            opacity: 1;
            height: 100%;
            background-color: theme-var(palette-appSpecific-panel-toolbar-background);
            box-shadow: 0 1px 0 0 theme-var(palette-divider);
          }
        }
        width: 100%;
        animation: $anim-name 0.5s cubic-bezier(0.4, 0, 0.2, 1);
        animation-delay: 0.5s;
        animation-fill-mode: forwards;
        display: flex;
        align-items: center;
        justify-content: center;

        &-title {
          $anim-name: unique-id();
          opacity: 0;
          @include keyframes($anim-name) {
            100% {
              opacity: 1;
            }
          }
          animation: $anim-name 0.5s cubic-bezier(0.4, 0, 0.2, 1);
          animation-delay: 1s;
          animation-fill-mode: forwards;
          height: theme-var(appSpecific-template-panel-toolbar-title-height);
          background-color: theme-var(palette-background-level1);
          font-weight: theme-var(fontWeight-lg);
          text-align: center;
          display: flex;
          align-items: center;
        }
      }

      &-left-panel {
        $anim-name: unique-id();
        opacity: 0;
        width: 0;
        background-color: transparent;
        @include keyframes($anim-name) {
          100% {
            opacity: 1;
            width: 100%;
            background-color: theme-var(palette-appSpecific-panel-left-panel-background);
          }
        }
        animation: $anim-name 0.5s cubic-bezier(0.4, 0, 0.2, 1);
        animation-delay: 1s;
        animation-fill-mode: forwards;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        max-height: 100%;

        &-header {
          width: 100%;
          display: flex;
          align-items: center;
          padding: 0 10px;
          box-shadow: 0 1px 0 0 theme-var(palette-divider);
          height: theme-var(appSpecific-template-panel-pages-panel-header-action-height);

          &-item {
            height: 1rem;
            width: 2.5rem;
            margin-inline-start: 0.5rem;
            border-radius: theme-var(shape-borderRadius);
            @include loading-shimmer();
          }
        }

        &-content {
          width: 100%;
          height: 100%;
          max-height: inherit;
          overflow-y: auto;
          overflow-x: hidden;
          touch-action: pan-y;
          padding-top: 0.5rem;
          //@include custom-scrollbar();

          &-container {
            position: relative;
            width: 100%;
            cursor: default;
            flex-grow: 1;
            overflow: hidden;
          }

          &-item {
            display: flex;
            flex-direction: column;
            width: 100%;
            padding-inline: 1rem;

            > div {
              @include loading-shimmer();
              height: 1rem;
              border-radius: theme-var(shape-borderRadius);
              margin-block: 0.35rem;

              &:nth-child(1) {
                width: 70%;
              }

              &:nth-child(2) {
                width: 55%;
              }

              &:nth-child(3) {
                width: 40%;
              }

              &:nth-child(4) {
                width: 70%;
              }

              &:nth-child(5) {
                width: 40%;
              }
            }

            &:last-child {
              > div {
                &:nth-child(3) {
                  opacity: 0.7;
                }

                &:nth-child(4) {
                  opacity: 0.45;
                }

                &:nth-child(5) {
                  opacity: 0.2;
                }
              }
            }
          }
        }
      }

      &-right-panel {
        $anim-name: unique-id();
        opacity: 0;
        width: 0;
        background-color: transparent;
        @include keyframes($anim-name) {
          100% {
            opacity: 1;
            width: 100%;
            background-color: theme-var(palette-appSpecific-panel-right-panel-background);
          }
        }
        animation: $anim-name 0.5s cubic-bezier(0.4, 0, 0.2, 1);
        animation-delay: 1s;
        animation-fill-mode: forwards;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        max-height: 100%;

        &-header {
          width: 100%;
          display: flex;
          align-items: center;
          padding-inline: 1rem;
          box-shadow: 0 1px 0 0 theme-var(palette-divider);
          height: theme-var(appSpecific-template-panel-properties-panel-header-title-height);

          &-item {
            height: 1rem;
            width: 2.5rem;
            margin-inline-start: 0.5rem;
            border-radius: theme-var(shape-borderRadius);
            @include loading-shimmer();
          }
        }

        &-sub-header {
          width: 100%;
          display: flex;
          flex-direction: column;
          padding-inline: 1rem;
          box-shadow: 0 1px 0 0 theme-var(palette-divider);
          padding-block-end: 0.5rem;

          &-item {
            height: 1rem;
            margin-inline-start: 0.5rem;
            margin-block-start: 0.5rem;
            border-radius: theme-var(shape-borderRadius);
            @include loading-shimmer();

            &:nth-child(1) {
              width: 4rem;
            }

            &:nth-child(2) {
              width: 80%;
            }
          }
        }

        &-content {
          width: 100%;
          height: 100%;
          max-height: inherit;
          overflow-y: auto;
          overflow-x: hidden;
          touch-action: pan-y;
          padding-top: 0.5rem;
          padding-inline: 1rem;
          //@include custom-scrollbar();

          &-container {
            position: relative;
            width: 100%;
            cursor: default;
            flex-grow: 1;
            overflow: hidden;
          }

          &-item {
            display: flex;
            align-items: center;
            width: 100%;
            padding-inline: 1rem;
            margin-block: 0.4rem;

            > div {
              @include loading-shimmer();
              height: 1rem;
              border-radius: theme-var(shape-borderRadius);
              margin-inline-end: 0.5rem;

              &:nth-child(1) {
                width: 1.5rem;
                height: 1.5rem;
                border-radius: 50%;
              }

              &:nth-child(2) {
                width: 55%;
              }
            }

            &-container {
              display: flex;
              flex-direction: column;
              width: 100%;

              &:nth-last-child(3) {
                > div {
                  opacity: 0.7;
                }
              }

              &:nth-last-child(2) {
                > div {
                  opacity: 0.45;
                }
              }

              &:nth-last-child(1) {
                > div {
                  opacity: 0.2;
                }
              }
            }

            &-header {
              height: 1rem;
              width: 4rem;
              margin-inline-start: 0.5rem;
              margin-block: 0.5rem;
              border-radius: theme-var(shape-borderRadius);
              @include loading-shimmer();
            }
          }
        }
      }

      &-content {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .MuiLinearProgress {

          &-root {
            width: 200px;
            margin-block-start: 1rem;
            background-color: theme-var(palette-neutral-softBg);
          }

          &-bar {
            background-color: theme-var(palette-neutral-softColor);
          }
        }

        &-loading-animation {
          width: 70px !important;
          height: 70px !important;
        }

      }
    }


  }

  .print-panel {

    &-toolbar {
      &-title {
        height: theme-var(appSpecific-print-panel-toolbar-title-height);
        font-weight: theme-var(fontWeight-lg);
        display: flex;
        align-items: center;
      }

      &-button {
        @include svg-color();
        margin-right: 0.5rem;

        &:last-child {
          margin-right: 0.75rem;
        }

      }
    }

    &-pages-panel {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      max-height: 100%;

      &-list {
        --List-decorator-size: 1rem;
        flex-grow: 1;
        margin-inline: -10px;
        padding: 0;

        &-item {
          height: theme-var(appSpecific-print-panel-pages-panel-item-height);
          width: 100%;
          font-weight: theme-var(fontWeight-md);

          svg {
            @include svg-color(theme-var(palette-common-white));
          }

          &-start-decorator {
            --List-decorator-size: 1rem;
            display: flex;
            align-items: center;
            justify-content: center;

            &-content {
              @include transition(background-color);
              height: 2px;
              width: 1rem;
              background-color: transparent;
            }
          }

          &-content-text {
            padding-inline: 0.75rem 0;
          }

          &.selected {
            .print-panel-pages-panel-list-item-start-decorator-content {
              background-color: theme-var(palette-primary-400);
            }

            .print-panel-pages-panel-list-item-content-text {
              color: theme-var(palette-primary-400);
            }
          }
        }
      }

      &-header {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 10px;
        box-shadow: 0 1px 0 0 theme-var(palette-divider);
        height: theme-var(appSpecific-print-panel-pages-panel-header-height);
        font-weight: theme-var(fontWeight-lg);
        padding-inline-end: 0;

        &-button {
          @include svg-color();
        }
      }

      &-list-item {
        border: 1px solid transparent;

        &:hover {
          border-color: theme-var(palette-neutral-plainActiveBg);
        }

        &.selected-sortable {
          background-color: theme-var(palette-neutral-plainActiveBg);
        }

      }

      &-content {
        width: 100%;
        height: 100%;
        max-height: inherit;
        overflow-y: auto;
        overflow-x: hidden;
        touch-action: pan-y;
        padding-top: 0.5rem;
        padding-bottom: 3rem;
        //@include custom-scrollbar();

        &-container {
          position: relative;
          width: 100%;
          cursor: default;
          flex-grow: 1;
          overflow: hidden;
        }
      }


    }

    &-content {
      inset: 0;
      width: var(--page-dimensional-percentage);
      height: var(--page-dimensional-percentage);
      overflow: auto;
      position: relative;
      transform: scale(var(--page-scale));
      transform-origin: top left;

      &-scroll-tooltip-anchor {
        position: absolute;
        right: 0;
        width: 1px;
        height: 1px;
      }

      &-page-container {
        height: fit-content;
        display: flex;
        flex-direction: column;
        position: relative;

        > .print-panel-content-page-container {
          > :first-child {
            margin-top: 1.5rem;
          }
        }
      }

      &-page {
        margin-bottom: 1rem;
        background-color: theme-var(palette-common-white);
        position: relative;
        overflow: hidden;
        backface-visibility: hidden;
        -moz-backface-visibility: hidden;
        -webkit-backface-visibility: hidden;

        &-template {
          z-index: -100;
          position: absolute;
          left: 0;
          top: 0;
          transform: translate(-200%, -200%);
          visibility: hidden;
          opacity: 0;
          pointer-events: none;
          user-select: none;
        }

        &-loading {
          margin-bottom: 1rem;
          margin-top: 1rem;
          background-color: theme-var(palette-background-level1);
          position: relative;
          overflow: hidden;
          backface-visibility: hidden;
          -moz-backface-visibility: hidden;
          -webkit-backface-visibility: hidden;
        }
      }

      &-element {
        &-text {

        }

        &-identifier {

        }

        &-barcode {
          position: relative;

          canvas {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;
          }
        }

        &-container {
          &-inner {
            position: relative;
            width: 100%;
            height: 100%;

          }
        }

        &-avery-label {
          font-size: 0;

          &-rect {
            width: 100%;
            height: 100%;
            position: relative;

            &-wrapper {
              position: relative;
              display: inline-block;
            }
          }
        }

        &-grid {
          font-size: 0;

          &-rect {
            width: 100%;
            height: 100%;
            position: relative;

            &-wrapper {
              position: relative;
              display: inline-block;
            }
          }
        }

        &-html {
          overflow-x: hidden;
          overflow-y: hidden;
          font-size: initial;
        }
      }
    }

    &-loading {

      .panel {
        &-toolbar {
          background: none;
          box-shadow: none;
        }

        &-left-panel-container {
          background-color: transparent;
        }

        &-content-preview {

        }
      }

      &-toolbar {
        $anim-name: unique-id();
        opacity: 0;
        height: 0;
        background-color: transparent;
        box-shadow: 0 1px 0 0 transparent;
        @include keyframes($anim-name) {
          100% {
            opacity: 1;
            height: 100%;
            background-color: theme-var(palette-appSpecific-panel-toolbar-background);
            box-shadow: 0 1px 0 0 theme-var(palette-divider);
          }
        }
        width: 100%;
        animation: $anim-name 0.5s cubic-bezier(0.4, 0, 0.2, 1);
        animation-delay: 0.5s;
        animation-fill-mode: forwards;
        display: flex;
        align-items: center;
        justify-content: center;

        &-title {
          $anim-name: unique-id();
          opacity: 0;
          @include keyframes($anim-name) {
            100% {
              opacity: 1;
            }
          }
          animation: $anim-name 0.5s cubic-bezier(0.4, 0, 0.2, 1);
          animation-delay: 1s;
          animation-fill-mode: forwards;
          height: theme-var(appSpecific-print-panel-toolbar-title-height);
          background-color: theme-var(palette-background-level1);
          font-weight: theme-var(fontWeight-lg);
          text-align: center;
          display: flex;
          align-items: center;
        }
      }

      &-left-panel {
        $anim-name: unique-id();
        opacity: 0;
        width: 0;
        background-color: transparent;
        @include keyframes($anim-name) {
          100% {
            opacity: 1;
            width: 100%;
          }
        }
        animation: $anim-name 0.5s cubic-bezier(0.4, 0, 0.2, 1);
        animation-delay: 1s;
        animation-fill-mode: forwards;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        max-height: 100%;

        &-header {
          width: 100%;
          display: flex;
          align-items: center;
          padding: 0 10px;
          box-shadow: 0 1px 0 0 theme-var(palette-divider);
          height: theme-var(appSpecific-print-panel-pages-panel-header-height);

          &-item {
            height: 1rem;
            width: 2.5rem;
            margin-inline-start: 0.5rem;
            border-radius: theme-var(shape-borderRadius);
            @include loading-shimmer();
          }
        }

        &-content {
          width: 100%;
          height: 100%;
          max-height: inherit;
          overflow-y: auto;
          overflow-x: hidden;
          touch-action: pan-y;
          padding-top: 0.5rem;
          //@include custom-scrollbar();

          &-container {
            position: relative;
            width: 100%;
            cursor: default;
            flex-grow: 1;
            overflow: hidden;
          }

          &-item {
            display: flex;
            flex-direction: column;
            width: 100%;
            padding-inline: 1rem;

            > div {
              @include loading-shimmer();
              height: 1rem;
              border-radius: theme-var(shape-borderRadius);
              margin-block: 0.35rem;

              &:nth-child(1) {
                width: 70%;
              }

              &:nth-child(2) {
                width: 55%;
              }

              &:nth-child(3) {
                width: 40%;
              }

              &:nth-child(4) {
                width: 70%;
              }

              &:nth-child(5) {
                width: 40%;
              }
            }

            &:last-child {
              > div {
                &:nth-child(3) {
                  opacity: 0.7;
                }

                &:nth-child(4) {
                  opacity: 0.45;
                }

                &:nth-child(5) {
                  opacity: 0.2;
                }
              }
            }
          }
        }
      }

      &-content {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .MuiLinearProgress {

          &-root {
            width: 200px;
            margin-block-start: 1rem;
            background-color: theme-var(palette-neutral-softBg);
          }

          &-bar {
            background-color: theme-var(palette-neutral-softColor);
          }
        }

        &-loading-animation {
          width: 70px !important;
          height: 70px !important;
        }

      }
    }
  }
}

// Components
html {

  .color-text-field {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &-color-indicator {
      position: relative;
      width: 16px;
      height: 16px;
      min-height: 16px;
      padding: 0;
      margin: 0;
      box-shadow: rgb(0 0 0 / 20%) 0 0 0 0.6px inset;
    }

    &-opacity-indicator {
      position: absolute;
      top: 0;
      right: 0;
      width: 50%;
      height: 100%;
      background-image: url("../svgs/color-text-field/opacity.svg");
    }
  }

  .input-label {
    font-size: theme-var(fontSize-sm);
    font-weight: theme-var(fontWeight-md);
    margin-block-end: 0.5rem;
  }


  .filepond {

    &--panel {
      background-color: transparent !important;
      border-radius: theme-var(shape-borderRadius);

      &[data-scalable=true]:not(.filepond--item-panel) {
        border: 2px dashed theme-var(palette-neutral-plainActiveBg) !important;
      }

      &-root {
        background-color: transparent !important;
      }
    }

    &--root {
      @include font-family(theme-var(fontFamily-body));
    }

    &--label-action {
      color: theme-var(palette-secondary-main);
      text-decoration: none;
    }

    &--drop-label {
      font-size: theme-var(fontSize-sm) !important;
      font-weight: theme-var(fontWeight-md);
      color: theme-var(palette-common-white);
    }
  }
}

// Popovers
html {

  .template-panel-toolbar-elements-menu {
    &-group {
      padding-inline: 0;
      margin-top: 0.5rem;
      width: 100%;
      justify-content: flex-start;
      --palette-neutral-plainActiveBg: transparent;

      &-arrow {
        @include transition((transform));
        @include svg-color(theme-var(palette-common-white));
        margin-left: 0.4rem;
      }

      &.active {
        svg {
          transform: rotate(-180deg);
        }
      }
    }

    &-group-element {
      cursor: pointer;

      svg {
        color: theme-var(palette-common-white);
        @include svg-color(theme-var(palette-common-white));
        margin-right: 0.5rem;
        margin-left: 1rem;
        width: 16px;
        height: 16px;
      }
    }
  }

  .color-text-field {

    &-color-picker-popover {
      width: 260px;
      background-image: none;
      background-color: theme-var(palette-background-level1);

      &-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
        padding-inline-start: 1rem;
        padding-inline-end: 0.5rem;
        --IconButton-size: 1.5rem;
      }

      &-body {
        display: flex;
        flex-direction: column;
        width: 100%;
        --thumb-size: 14px;

        &-tools {
          display: flex;
          flex-direction: row;
          width: 100%;
          padding-inline: 1rem;

          > div {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            &:first-of-type {
              padding-inline-end: 1rem;
            }

            &:last-of-type {
              flex-grow: 1;
            }
          }
        }

        &-slider-container {
          width: 100%;

          .hue-slider, .alpha-slider {
            --Slider-track-size: var(--thumb-size);
            --Slider-thumb-size: var(--thumb-size);
            --Slider-thumb-radius: 50%;
            padding-block: calc(var(--thumb-size) * 0.75);

            .JoySlider-rail {
              opacity: 1;
              box-shadow: rgb(0 0 0 / 20%) 0 0 0 0.6px inset;
            }

            .JoySlider-track {
              color: transparent;
              background-color: transparent;
              border: 0;
            }

            .JoySlider-thumb {
              left: css-clamp(3%, var(--left), 97%) !important;
              --Slider-thumb-color: #{theme-var(palette-common-white)};
              background-color: transparent;
              outline: none !important;

              &:before {
                width: calc(100% - 4px);
                height: calc(100% - 4px);
              }
            }
          }

          .hue-slider {
            .JoySlider-rail {
              background: linear-gradient(to right, rgb(255, 0, 0) 0%, rgb(255, 255, 0) 17%, rgb(0, 255, 0) 33%, rgb(0, 255, 255) 50%, rgb(0, 0, 255) 67%, rgb(255, 0, 255) 83%, rgb(255, 0, 0) 100%);
            }
          }

          .alpha-slider {
            .JoySlider-rail {
              background: linear-gradient(to right, rgba(var(--rgb-r), var(--rgb-g), var(--rgb-b), 0.5) 0%, rgba(var(--rgb-r), var(--rgb-g), var(--rgb-b), 1) 100%);

              &:before {
                content: '';
                width: 100%;
                height: 100%;
                z-index: -1;
                position: absolute;
                background-image: url("../svgs/color-text-field/alpha-mask.png");
                border-radius: theme-var(Slider-track-radius);
              }
            }
          }
        }

        &-color-space {
          width: 100%;
          height: 220px;
          position: relative;
          background-image: linear-gradient(to top, theme-var(palette-common-black), transparent), linear-gradient(to right, theme-var(palette-common-white), transparent);
          margin-block-end: 1rem;

          &-thumb {
            position: absolute;
            border: 3px solid theme-var(palette-common-white);
            border-radius: 50%;
            width: var(--thumb-size);
            height: var(--thumb-size);
            margin-left: calc(var(--thumb-size) * -0.5);
            margin-bottom: calc(var(--thumb-size) * -0.5);
            will-change: left, bottom;
            transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
          }
        }

        &-suggestions {
          display: flex;
          flex-direction: column;
          width: 100%;
          padding-inline: 1rem;
          margin-top: 0.5rem;
          padding-top: 1rem;
          box-shadow: 0 0 0 1px theme-var(palette-divider);
        }

        &-suggestion {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 0.5rem;

          .values {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            > * {
              margin-right: 0.4rem;
            }
          }

        }
      }
    }
  }

  .image-manipulator {

    &-popover {
      width: 260px;
      background-image: none;
      background-color: theme-var(palette-background-level1);

      &-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
        padding-inline-start: 1rem;
        padding-inline-end: 0.5rem;
        --IconButton-size: 1.5rem;
      }

      &-body {
        display: flex;
        flex-direction: column;
        width: 100%;

        &-commit-button {
          margin-block-end: 0.5rem;
          margin-inline: 0.5rem;
        }
      }

    }

    &-image-cropper {
      width: 240px;
      height: 240px;
      margin: 1rem;

      &-container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
      }

      &-circular-progress {
        position: absolute;
      }
    }
  }

  .Toastify__toast {
    border-radius: theme-var(shape-borderRadius);

    .toast-content {
      display: flex;
      align-items: center;
      justify-content: center;
    }

  }
}

// Dialogs
html {

  .quick-actions-panel {
    &-dialog {
      width: min(400px, 40vw);
      box-shadow: theme-var(shadow-sm);
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0;
      background-color: theme-var(palette-background-level1);
      top: calc(#{theme-var(appSpecific-panel-toolbar-height)} + 5rem);
      transform: translate(-50%, 0);
    }

    &-search-input {
      width: 100%;
      --Input-focusedHighlight: transparent;
      --Input-focusedThickness: 0;
      background-color: theme-var(palette-background-level1);
      min-height: 3rem;
      padding-inline: 1rem;
    }

    &-entry-container {
      //@include custom-scrollbar();
      max-height: 270px;
      overflow-y: auto;
      width: 100%;
      padding-inline: 0.5rem;
    }

    &-entry {
      width: 100%;

      .end-decorator {
        justify-content: flex-end;
      }
    }
  }

  .new-avery-label-element-dialog {
    --ModalDialog-minWidth: 300px;
    background-color: theme-var(palette-background-surface);
    box-shadow: theme-var(shadow-sm);
    max-width: theme-var(ModalDialog-minWidth);

    .radio {
      justify-content: flex-end;
      margin-top: 0.5rem;

      &:not(:last-child) {
        margin-bottom: 2rem;
      }
    }

    p {
      color: theme-var(palette-text-tertiary);
    }
  }

  .print-questionnaire-dialog {

  }

  .add-printed-blank-pages-dialog {
    --ModalDialog-minWidth: min(500px, 95vw);
  }

  .move-printed-page-dialog {

  }
}

// Views
html {

  // Root view
  .app-views {
    min-height: 100vh;
    background-color: theme-var(palette-background-body);

    @supports (-webkit-touch-callout: none) {
      min-height: -webkit-fill-available;
    }

    .app {
      &.centered {
        width: 100vw;
        min-height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;

        @supports (-webkit-touch-callout: none) {
          min-height: -webkit-fill-available;
        }

      }

      &.error-cursor {
        cursor: none;
      }

      .loading-bar-container {
        $color: theme-var(palette-secondary-main);

        .loading-bar {
          background: $color !important;
          color: $color !important;

          > div {
            box-shadow: $color 0 0 10px, $color 0 0 5px;;
          }
        }
      }
    }
  }

  .error-view {
    max-width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    * {
      cursor: none !important;
    }

    .foreground {
      position: fixed;
      pointer-events: none;
      z-index: 999;
      width: 200vw;
      height: 200vh;
      left: -100vw;
      top: -100vh;
      transform-origin: center;
      mask-image: radial-gradient(15vw at 50% 50%, transparent 100%, black 100%);
      //noinspection CssInvalidPropertyValue
      -webkit-mask-image: radial-gradient(15vw at 50% 50%, transparent 100%, black 100%);
    }

    .flashlight {
      position: absolute;
      pointer-events: none;
      z-index: 999;
      top: 0;
      left: 0;
      transform: translate(-50%, -50%);
      box-shadow: 0 0 100px 20px theme-var(palette-text-primary);
      background: rgba(theme-var(palette-text-primaryChannel) / 0.23);
      @include svg-color(theme-var(palette-text-primary));
    }

    @supports not ((backdrop-filter: blur(0px)) or (-webkit-backdrop-filter: blur(0px))) {
      .foreground {
        background-color: theme-var(palette-background-body);
      }
    }

    @media screen and (min-width: 746px) {
      .foreground {
        backdrop-filter: blur(3px);
        -webkit-backdrop-filter: blur(3px);
      }
    }


    .title {
      @include rfs(12rem);
      letter-spacing: 10px;
      margin: 0;
      font-weight: 700;

      span {
        color: theme-var(palette-text-primary);
        text-shadow: 1px 1px 0 theme-var(palette-primary-main),
        -1px -1px 0px rgba(theme-var(palette-primary-mainChannel) / 0.5),
        0px 0px 4px rgba(theme-var(palette-primary-mainChannel) / 0.8);
      }
    }

    .description {
      text-align: center;
      margin: 2rem;
    }
  }

  .print-view {

    .panel-left-panel-container {
      background-color: transparent;
    }

    @include print-media('.generating-pdf') {

      .panel-toolbar, .panel-left-panel-container {
        display: none;
      }

      .panel-content-preview {
        &-content {
          background-color: transparent;
        }

        &-container {
          position: initial;
          inset: 0;
        }
      }

      .print-panel {
        &-content {
          //@include custom-scrollbar();

          &-page {
            margin: 0;

            &-container {
              margin: 0 !important;

              > :first-child {
                margin-top: 0 !important;
              }
            }
          }
        }
      }
    }
  }
}

@media print {

  html {
    * {
      -webkit-print-color-adjust: exact !important; /* Ensures colors are printed */
    }

    body {
      width: 100%;
      height: auto;
      margin: 0;
      padding: 0;

      &.mobile-device {


        .print-view .print-panel-content {
          transform: scale(0.88) !important;
          transform-origin: top left !important;
          width: 113.63% !important;
          height: 113.63% !important;
        }
      }
    }

    .app-views {
      background-color: white;
    }

    .template-view {
      // to disallow printing of the template view
      display: none;
    }

    .print-view {
      display: initial;
      height: var(--print-pages-height);

      .panel-toolbar, .panel-left-panel-container {
        display: none;
      }

      .panel-content-preview {

        &-content {
          background-color: white;
        }

        &-container {
          position: initial;
          background-color: white;
          inset: 0;
        }
      }

      .print-panel {
        &-content {
          transform: none !important;
          transform-origin: initial !important;
          width: 100.1% !important;
          height: 100.1% !important;

          &-page {
            margin: 0 !important;
            padding: 0 !important;

            &-container {
              margin: 0 !important;
              padding: 0 !important;

              > :first-child {
                margin: 0 !important;
                padding: 0 !important;
              }
            }
          }
        }
      }

    }
  }
}