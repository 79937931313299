@import "./keyframes";
@import "../functions/theme-var";

/// @mixin loading-shimmer
/// @param {String} $background-size
/// @param {String} $background
@mixin loading-shimmer(
  $background-width: 100vw,
  $background-height: 100vh,
  $background: theme-var(palette-neutral-700) linear-gradient(to right, theme-var(palette-neutral-700) 0%, theme-var(palette-neutral-600) 10%, theme-var(palette-neutral-500) 20%, theme-var(palette-neutral-600) 30%, theme-var(palette-neutral-700) 50%, theme-var(palette-neutral-700) 100%) no-repeat
) {
  position: relative;
  background: $background;
  background-size: $background-width $background-height;

  $anim-name: unique-id();
  @include keyframes($anim-name) {
    0% {
      background-position: calc(-#{$background-width} / 2) 0;
    }
    100% {
      background-position: calc(#{$background-width} / 2) 0;
    }
  }

  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: $anim-name;
  animation-timing-function: ease-in-out;
}
